/*!
 * _top.js
 */
//# =================================================================
//# loadイベント（ready)
//# =================================================================
$(function() {
  top_001($("h1.tlt"));
});
//# =================================================================
//# スクロールイベント
//# =================================================================
$(window).scroll(function() {
  top_001($("h1.tlt"));
});
//#==================================================================
//# function : top_001
//# Contents : textillate.jsの動作制御（対象：tltクラスがを持つオブジェクト）
//#            delighters.jsの併用が必要
//# =================================================================
function top_001(target) {
  target.each(function(i) {
    var parent = target.eq(i).parents("section");
    if (
      parent.hasClass("delighter") &&
      parent.hasClass("started") &&
      !parent.hasClass("ended")
    ) {
      target
        .eq(i)
        .css("opacity", 1)
        .textillate({
          minDisplayTime: 1000,
          autoStart: true,
          // アニメーション設定(開始)
          in: {
            effect: "fadeIn"
          }
        });
    }
  });
}
